@use "../../theme";

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker-popper {
    user-select: none;

    .react-datepicker__header {
        background-color: theme.$white-color;
        border-bottom: none;
        padding-bottom: 0;
    }

    .react-datepicker__day:hover {
        border-radius: 0;
        background-color: theme.$black-color;
        color: theme.$white-color;

        &:active {
            background-color: theme.$black-color;
        }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
        border-radius: 0;
        background-color: theme.$black-color;

        &:hover {
            background-color: theme.$black-color;
        }
    }

    .react-datepicker__day--today {
        border: 1px solid theme.$border-color;
        font-weight: 500;
    }
}

.datetimepicker-input {
    display: flex;
    flex-direction: row;
    border: 1px solid theme.$border-color;
    padding: 5px 7px;
    height: 39px;
    width: 100%;
    align-items: center;
    cursor: pointer;
    position: relative;

    span {
        letter-spacing: 1px;
    }

    img {
        position: absolute;
        right: 0px;
        height: 17px;
        margin: 0px 5px;
        padding-right: 6px;
        color: theme.$header-color;
        cursor: pointer;
    }

    svg {
        width: 16px;
        margin-left: 10px;
    }

    .timepicker-input {
        display: flex;
        flex-direction: row;

        .input-time-field {
            margin: 0 2px;
            width: 20px;
            outline: none;
            border: none;
            text-align: center;
        }

        span {
            margin: 0 2px;
        }
    }
}

.error-field {
    outline-color: theme.$error-color;
    border: 2px solid theme.$error-color;
}

.rotate180 {
    transform: rotate(180deg);
}

.timepickerbox {
    display: flex;
    flex-direction: row;
    position: absolute;
    border: 1px solid theme.$border-color;
    margin-top: 5px;
    width: fit-content;
    height: 120px;
    background: theme.$white-color;
    padding: 12px 6px;
    justify-content: space-between;

    .timepicker-item {
        display: flex;
        flex-direction: column;
        width: 50px;
        padding: 0 6px;
        justify-content: space-between;

        img {
            padding: 6px 12px 18px 12px;
            min-height: 32px;
        }

        input {
            outline: none;
            border: 1px solid theme.$border-color;
            text-align: center;
            min-height: 32px;
        }
    }
}