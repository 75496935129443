@use "../../theme";

.assign-project-container {
    width: 90%;
    height: 90%;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    background: theme.$white-color;
    box-shadow: 1.09375px 2.1875px 7.65625px 4.375px rgba(132, 129, 129, 0.25);

    h3 {
        width: 70%;
        margin-bottom: 20px;
        margin-top: 10px;
        color: theme.$base-color;
    }

    .select-app-div {
        width: 70%;
        display: flex;
        margin-bottom: 3%;

        span {
            color: theme.$disabled-color;
        }
    }

    .select-app {
        height: 18%;
        display: flex;
        width: 70%;
        justify-content: space-between;
        margin-bottom: 2%;

        .apptype-select {
            width: 16%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border: 2px solid theme.$border-color;
            align-items: center;

            div {
                width: 100%;
                margin-left: 10%;
            }

            img {
                margin-top: 30px;
                width: 32%;
            }

            label {
                margin-bottom: 10px;
                ;
            }
        }

        .selectedApp {
            border: 2px solid theme.$base-color;
        }
    }

    @media(max-resolution:125dpi) {
        .admin-assignprj-textbox {
            border: 1px solid #d0d0ce;
            width: 480px;
            padding: 8px;
            outline: none !important;
            height: 35px;
            margin: 5px;
        }
    }

    @media(max-resolution:110dpi) {
        .admin-assignprj-textbox {
            border: 1px solid #d0d0ce;
            width: 621px;
            padding: 8px;
            outline: none !important;
            height: 35px;
            margin: 5px;
        }
    }


    .assign-project {
        display: flex;
        width: 70%;
        justify-content: space-between;
        margin: 1%;

        label {
            width: 30%;
            display: block;
            margin-bottom: 10px;
        }

        .assign-prject-content {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            width: 60%;

            .assign-new-prj-list {
                margin-top: 1%;
                width: 100%;
                max-height: 118px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                overflow-y: scroll;
                border: 1px solid #D0D0CE;

                .Prj-Label {
                    margin-bottom: 6px;
                    margin-top: 2px;
                    color: theme.$white-color;
                    display: flex;
                    flex-direction: row;
                    width: 30%;
                    background-color: theme.$base-color;
                    justify-content: space-evenly;
                    align-items: center;
                    height: 30px;
                    margin-left: 8px;

                    img {
                        height: 70%;
                        width: 20%;
                    }

                    span {
                        display: block;
                        width: 50%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }

    .available-projects {
        width: 70%;
        height: 30%;
        display: flex;
        flex-direction: column;

        label {
            width: 30%;
            display: block;
            margin-bottom: 10px;
            //color: theme.$header-color;
        }

        .available-project-list {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            div {
                width: 20%;
                height: 100%;
                border: 1px solid theme.$border-color;
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    width: 20%;
                }

            }
        }
    }


    @media(max-resolution:150dpi) {
        .create-user-btn {
            margin-left: 20px;
            width: 70%;
            display: flex;
            justify-content: end;
            margin-top: 2%;

            .--savebtn {
                width: 15%;
            }
        }
    }

    @media(max-resolution:125dpi) {
        .create-user-btn {
            margin-left: 10px;
            width: 70%;
            display: flex;
            justify-content: end;
            margin-bottom: 6%;

            .--savebtn {
                width: 15%;
            }
        }
    }
}

@media(max-resolution:150dpi) {
    .assign-project-container {
        height: 90%;
        margin-top: 20px;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;

        h3 {
            width: 70%;
            //height: 10%;
            color: theme.$base-color;
            margin-bottom: 10px;
            margin-top: 10px;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;

        }
    }
}

@media(max-resolution:125dpi) {
    .assign-project-container {
        // height: 70%;
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;

        h3 {
            width: 70%;
            //height: 10%;
            color: theme.$base-color;
            margin-top: 5%;
            margin-bottom: 2%;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
        }

    }
}