@use "../../theme";

.not-found{
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;

    img{
        width:40%
    }

    p{
        font-weight: 400;
        font-size: 32px;
        line-height: 28px;
        margin-top: 2%;
        margin-bottom: 1%;
    }

    .reload{
        width: 12%;
    }
}
