@use "../../theme";

.--edit-label-container-notification {
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;

    .main-card {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        background: theme.$white-color;
        padding: 24px 24px 10px 24px;


        .--notif-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0 0 16px 16px;


            .heading {
                color: theme.$base-color;
            }
        }

        .--notif-body {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            height: calc(100% - 61px);

            .--Ntable-header,
            .--Ntable-item {
                border-bottom: 1px solid theme.$border-color;
                padding: 15px 0;



                div {

                    align-self: center;

                }
            }

            .--Ntable-item {
                font-size: 14px;
            }


            .--Ntable-header {
                color: theme.$header-color;
                font-size: 12px;

            }

            .--Ntable-sno,
            .--Ntable-col{
                text-align: left;
                margin-left: 10px;
                padding-left: 20px;
            }

            .--Ntable-body {
                font-weight: 400;
                overflow-y: auto;

                // .--Ntable-item {
                //     text-align: center;

                    .--Ntable-edit,
                    .--Ntable-del {
                        &.disabled {
                            opacity: 0.4;
                        }
                        margin-left: 10px;
                        padding-left: 10px;
                    }

                    .--Ntable-col {
                        // display: flex;
                        // justify-content: center;
                        


                        .--color-dot {
                            height: 24px;
                            width: 24px;
                            border-radius: 32px;
                        }
                    }
                // }
            }

            &.--empty {
                align-items: center;
                margin-top: 70px;

                .--tag-icon {
                    margin-bottom: 25px;
                }

                .--notif-item {
                    margin: 0;
                    font-size: 14px;
                }
            }
        }

        .--label-footer {
            display: flex;
            flex-direction: row-reverse;
            color: theme.$subtext-color;

            .--savebtn {
                width: 115px;
            }
        }
    }

    .main-block-screen {
        .popup-main {
            min-width: 400px;
            height: 189px;


            .body-content {
                line-height: 20px;
                font-size: 14px;
                width: 352px;
                height: 20px;

            }

            .--title-head dds-h5 {
                top: 14px;
                left: 24px;
                line-height: 24px;
                width: 318px;
            }



            .--footer {
                height: 80px;


                .btn-main {
                    align-content: center;
                    font-weight: 400;
                    height: 32px;
                    padding: 5px 10px;
                    text-align: left;
                    width: 68px;
                    gap: 0px;
                    opacity: 1;



                }

                .--testsave {
                    background-color: theme.$error-color;
                }
            }
        }
    }
}