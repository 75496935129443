@use "../../theme";

.create-notification-container {
    width: 75%;
    height: 80%;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    background: theme.$white-color;
    box-shadow: 1.09375px 2.1875px 7.65625px 4.375px rgba(132, 129, 129, 0.25);

    h3 {
        width: 60%;
        //height: 10%;
        color: theme.$base-color;
        margin-bottom: 25px;
        margin-top: 30px;

    }

    .create-notification-div {
        display: flex;
        flex-direction: row;
        width: 60%;
        margin: 15px;
        justify-content: space-between;

        label {
            width: 30%;
        }

        textarea {
            width: 100%;
            height: 100px;
            border-color: theme.$border-color;
        }
    }

    .create-notification-btn {
        //margin-left: 20px;
        width: 60%;
        display: flex;
        justify-content: end;
        margin-top: 1%;

        .--savebtn {
            margin: 0;
        }
    }
}