@use "../../theme";

.main-header {
    height: 60px;
    padding: 10px;
    text-align: end;
    border-bottom: 1px solid theme.$black-color;
    display: flex;
    flex-direction: row-reverse;
    position: relative;

    &.no-underline {
        border: none;
    }

    .--logo {
        position: absolute;
        left: 0px;
        top: 0;
        cursor: pointer;
        color: theme.$white-color;
        height: 36px;
        text-align: center;
        width: 250px;
        margin-left: -8px;
        zoom: 2;

    }

    .--image-box {
        display: flex;
        justify-content: space-between;
        margin: 0px 25px;
        width: 100px;


        .impicon {
            cursor: not-allowed;
            opacity: 0.5;

        }

        img {
            height: 25px;
            margin: 5px 15px;
            cursor: pointer;

            &.--active {
                height: 35px;
                margin: -2px 10px 0px 10px;
            }
        }
    }

    .--formItem {
        width: 300px;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        &.--init {
            width: 260px;
            //transform: translate(0px, 250px);
        }

        .searchbox-main {
            height: 100%;
            width: 100%;
        }

        label {
            margin-right: 10px;
        }

        .--headerproj {
            width: 250px;
            text-align: left;
        }

        .--renameheaderproj {
            text-align: left;
        }
    }

    .--UserDropdown {
        display: flex;
        flex-direction: column;
        position: absolute;
        border: 1px solid theme.$border-color;
        top: 55px;
        background: theme.$white-color;
        z-index: 7;

        .--menu-row {
            padding: 5px;
            margin: 5px;
            text-align: start;

            &:hover {
                background: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #FFFFFF";
                cursor: pointer;
            }
        }
        .--menu-version {
            padding: 11px 10px;
            color: #777777;
            border-top: 1px solid theme.$border-color;
            text-align: start;

        }

        .--userInfo {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            border-bottom: 1px solid theme.$border-color;
            // border-radius: 2px;

            .--userBox-left {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 10px;
                padding: 10px;
                background: theme.$header-color;
                color: theme.$white-color;

            }

            .--userBox-right {
                justify-content: center;
                align-items: flex-start;
                margin: 5px 5px 5px 0%;
                height: 50px;
                display: flex;
                flex-direction: column;
            }
        }
    }
}