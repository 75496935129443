@use "../../theme";

.btn-main {
    margin-left: 22px;
    border: 1px solid;
    text-align: center;
    width: 110px;
    height: 40px;
    padding: 9px 16px;
    gap: 8px;

    background: theme.$base-color;
    color: theme.$white-color;
    // border-radius: 2px;
    cursor: pointer;

    &.error {
        background: theme.$error-color;

    }

    &.disabled {
        background: theme.$base-color-disabled;
        cursor: not-allowed;
    }

    &.outline {
        background: theme.$white-color;
        color: theme.$base-color;
        border-color: theme.$base-color;

        &.error {
            color: theme.$error-color;
            border-color: theme.$error-color;
        }

        &.disabled {
            color: theme.$disabled-color;
            border-color: theme.$disabled-color;
        }
    }
}