@use "../../theme";

.create-user-container {
    width: 90%;
    height: 90%;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: theme.$white-color;
    box-shadow: 1.09375px 2.1875px 7.65625px 4.375px rgba(132, 129, 129, 0.25);
    overflow: hidden;
    //overflow-y: scroll;

    h3 {
        width: 70%;
        //height: 10%;
        color: theme.$base-color;
        margin-bottom: 20px;
        margin-top: 30px;

    }


    .create-user-div {
        width: 70%;
        // height: 10%;
        margin: 7px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .disabled {
            cursor: not-allowed !important;
        }
        .error-textbox {
            border: 1px solid theme.$error-color;
            width: 470px;
            padding: 8px;
            outline: none !important;
            height: 35px;
        }

        .create-user-txtbox {
            height: 10%;
            width: 60%;
            


            .searchbox-main {
                background: theme.$white-color;
                border: 1px solid theme.$border-color;
                align-items: center;
                display: flex;
                height: 38px;

                img {
                    height: 15px;
                }
            }
        }

        

        label {
            width: 30%;
            display: block;
        }


        .select-prj-div {
            width: 60%;

            .app-type {
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;
                align-items: center;
                gap: 8px;

                .app-type-item {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    width: 90px;
                }

                .radio-select {
                    align-items: center;
                    display: flex;

                    .radio::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: theme.$white-color;
                        border: 2px solid theme.$border-color;
                    }

                    .radio:checked:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: theme.$white-color;
                        border: 2px solid theme.$base-color;
                    }

                    .radio {
                        content: '';
                        position: relative;
                        display: inline-block;
                        margin-right: 5px;
                        width: 14px;
                        height: 14px;
                        visibility: visible;
                        border: none;
                        appearance: none;
                        accent-color: theme.$white-color;
                    }
                }
            }

            .select-project {
                .assign-new-prj-list {
                    width: 470px;
                    height: 50px;
                    border: 2px solid theme.$border-color;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    overflow-y: scroll;

                    .Prj-Label {
                        margin: 5px;
                        color: theme.$white-color;
                        display: flex;
                        flex-direction: row;
                        width: 30%;
                        background-color: theme.$base-color;
                        justify-content: space-evenly;
                        align-items: center;
                        height: 50%;

                        span {
                            display: block;
                            width: 50%;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        img {
                            height: 70%
                        }
                    }
                }
            }
        }
    }

    

    .create-user-password {
        width: 60%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5%;
        position: relative;


        label {
            width: 30%;
        }

        .create-pwd {
            //width: 70%;
            display: flex;
            z-index: 10;
            position: absolute;
            left: 100%;
            width: 30%;
        }

        span {
            color: theme.$error-color;
        }
    }

    .create-user-btn {
        width: 745px;
        display: flex;
        justify-content: end;
        margin-top: 2%;

        .--savebtn {
            width: 79px;
            margin: 0;
        }

    }

}
.error-message{
    color: theme.$error-color;
    margin-left: 240px;
    
}







@media(max-resolution:150dpi) {
    .create-user-container {
        height: 90%;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;

        h3 {
            width: 70%;
            //height: 10%;
            color: theme.$base-color;
            margin-bottom: 10px;
            margin-top: 10px;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;

        }

        .create-user-div {
            width: 70%;
            // height: 10%;
            margin: 5px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .create-user-txtbox {
                height: 10%;
                width: 60%;
                
            }
        }

    }
}

@media(max-resolution:125dpi) {
    .create-user-container {
        height: 90%;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;

        h3 {
            width: 70%;
            //height: 10%;
            color: theme.$base-color;
            margin-bottom: 20px;
            margin-top: 10px;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;

        }

        .create-user-div {
            width: 70%;
            // height: 10%;
            margin: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            
                .row{
                    display:flex;
                    flex-direction: row;
                
            }
            


            .create-user-txtbox {
                height: 10%;
                width: 60%;
                display: flex;
                flex-direction: row;
                .error-dropdown{
                border:1px solid theme.$error-color;
            }
                .info-icon{
                    height: 15px;
                    margin-top: 10px;
                    margin-left: 5px;
                }
                
            }
        }

    }
}