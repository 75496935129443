@use "../theme";

.Admin-body {
    height: calc(100% - 60px);
    display: flex;
    flex-direction: row;

    .--content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .--mainbox {
            width: 100%;
            height: 100%;
            align-content: center;
            justify-content: center;
            align-items: center;
            display: flex;
            overflow: hidden;

            h3 {
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
            }

            .admin-textbox {
                border: 1px solid theme.$border-color;
                width: 470px;
                padding: 8px;
                outline: none !important;
                height: 35px;
            }
            .radio:checked:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: theme.$white-color;
                border: 2px solid theme.$base-color;
            }
            .radio {
                content: '';
                position: relative;
                display: inline-block;
                visibility: visible;
                border: none;
                accent-color: theme.$white-color;
            }
            .chkbox {
                accent-color: theme.$base-color;
            }

            input.error-field {
                outline-color: theme.$error-color;
                border: 2px solid theme.$error-color;
            }

            .disable {
                opacity: 0.7;
                pointer-events: none;

            }
        }
    }
}

@media(max-resolution:175dpi) {
    .Admin-body {
        height: calc(100% - 50px);

    }
}

@media(max-resolution:150dpi) {
    .Admin-body {
        height: calc(100% - 60px);

    }
}

@media(max-resolution:125dpi) {
    .Admin-body {
        height: calc(100% - 60px);


    }
}

@media(max-resolution:100dpi) {
    #root {
        .Admin-body {
            height: calc(100% - 60px);

            .radio:checked:after {
                width: 10px;
                height: 10px;
                border-radius: 25px;
                background-color: theme.$white-color;
                content: '';
                display: inline-block;
                visibility: visible;
                border: 6px solid theme.$base-color;
                accent-color: theme.$white-color;
            }

        }
    }

}