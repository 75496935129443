@use "../../theme";

.SSO-config-container {
	width: 90%;
	height: 90%;
	/* align-items: center; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	background: theme.$white-color;
	box-shadow: 1.09375px 2.1875px 7.65625px 4.375px rgba(132, 129, 129, 0.25);

	h3 {
		//margin-right: 41%;
		color: theme.$base-color;
		margin-bottom: 20px;
		margin-top: 30px;
		width: 60%
	}

	.other-config-div {
		width: 60%;
		height: 8%;
		margin: 10px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		position: relative;

		.info {
			width: 30%;
			display: flex;
			flex-direction: row;
			//justify-content: center;
			align-items: center;
			position: absolute;
			left: 100%;


			img {
				margin-left: 5px;
				height: 20%;

			}
		}

		.admin-radio-button {
			display: flex;
			flex-direction: row;
			width: 100%;
			justify-content: center;
		}

		.radio-button {
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			align-items: center;
			width: 50%;

			label {
				margin-bottom: 0px;
			}
		}

		label {
			width: 50%;
			display: block;
			margin-bottom: 10px;
			//color: theme.$header-color;
		}
	}

	.other-config-btn {
		margin-top: 5%;
		width: 65%;
		display: flex;
		justify-content: end;
		//margin-top: 1%;

		.--savebtn {
			margin-left: 22px;
		}
	}
}

.--edit-label-container-sso {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.main-card {
		width: 90%;
		height: 90%;
		display: flex;
		flex-direction: column;
		// justify-content: space-between;
		background: theme.$white-color;
		padding: 24px 24px 10px 24px;

		.--notif-header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 0 0 16px 16px;

			.heading {
				color: theme.$base-color;
			}
		}

		.--notif-body {
			display: flex;
			flex-direction: column;
			margin-top: 20px;
			height: calc(100% - 61px);

			.--Ntable-header,
			.--Ntable-item {
				border-bottom: 1px solid theme.$border-color;
				padding: 15px 0;

				div {
					align-self: center;
				}
			}

			.--Ntable-sno,
			.--Ntable-del,
			.--Ntable-edit,
			.--Ntable-col {
				text-align: center;
			}




			.--Ntable-body {
				font-weight: 400;
				overflow-y: auto;

				.--Ntable-item {

					.--Ntable-edit,
					.--Ntable-del {
						&.disabled {
							opacity: 0.4;
						}
					}

					.--Ntable-col {
						display: flex;
						justify-content: center;


						.--color-dot {
							height: 24px;
							width: 24px;
							border-radius: 32px;
						}
					}
				}
			}

			&.--empty {
				align-items: center;
				margin-top: 70px;

				.--tag-icon {
					margin-bottom: 25px;
				}

				.--notif-item {
					margin: 0;
				}
			}
		}

		.--label-footer {
			display: flex;
			flex-direction: row-reverse;
			color: theme.$subtext-color;

			.--savebtn {
				width: 115px;
			}
		}
	}

	.main-block-screen {
		.popup-main {
			// .body-content {
			// 	margin-left: 12px;
			// }

			.--footer {
				.btn-main {
					font-weight: 400;
					height: 32px;
					width: 68px;
					padding: 5px 12px 5px 12px;
					gap: 8px;

				}

				.--testsave {
					background-color: theme.$error-color;
				}
			}
		}
	}
}