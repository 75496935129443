@use "../../theme";

.searchbox-main {
    background: theme.$white-color;
    border: 1px solid theme.$border-color;
    // border-radius: 4px;
    align-items: center;
    display: flex;
    height: 32px;

    input {
        outline: none;
        border: none;
    }

    img {
        margin: 0 10px;
        height: 12px; 
    }
}