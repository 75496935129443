* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
}

html,
body,
#root,
.app {
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}



::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: white;
    overflow-x: auto;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #757778;
    overflow-x: auto;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.disable-feature {
    cursor: not-allowed !important;
    pointer-events: none;
    opacity: 0.6;

    &:hover {
        background: none;
        border: none;
    }
}

.dds-h1 {
    font-size: 56px;
    font-weight: 600;
    line-height: 72px;
}

.dds-h2 {
    font-size: 40px;
    font-weight: 600;
    line-height: 52px;
}

.dds-h2-sm {
    font-size: 35px;
    font-weight: 400;
    line-height: 42px;
}

.dds-h3 {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
}

.dds-h3-sm {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
}

.dds-h4 {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
}

.dds-h4-mm {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
}

.dds-h4-lm {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
}

.dds-h4-sm {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
}

.dds-h5 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.dds-h5-lm {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
}

.dds-h5-sm {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.dds-h6 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}

.dds-h6-sm {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}

.dds-body {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

}

.dds-body-semibold {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.dds-body-bold {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
}

.dds-body-normal {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.dds-link {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.dds-label {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.dds-label-semibold {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
}

.dds-link-sm {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.dds-icon {
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
}

.dds-txt {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
}

.dds-tb {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
}