@use "../../theme";

.main-tree {
    border: 0;
    flex: 0 0 230px;
    transition: all 2s;
    display: flex;
    flex-direction: column;
    position: relative;

    .--mainbox {
        height: 100%;

        .--search {
            margin: 5px;
            display: flex;
            flex-direction: row;
            transition: all 2s;

            img {
                margin: 5px;
                height: 15px;
                cursor: pointer;
            }
        }
    }

    >div {
        flex-direction: column;
        border-right: 3px solid theme.$border-color;
        display: flex;
        overflow-y: auto;
    }

    &.no-leaf-actions {
        .tree-container {
            .leaf-node {
                .icon {
                    display: none !important;
                }
            }
        }
    }

    .orphan-tree {
        flex: 0 0 22%;
        padding-bottom: 2px;
    }

    .tree-container {
        height: 100%;
        flex-grow: 1;
        max-width: 400px;
        overflow-y: auto;

        .tree-node {
            padding: 4px 2px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 8px;
            user-select: none;
            cursor: pointer;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;

            .disabled{
                color:theme.$disabled-color
            }

            .disabledIcon{
                opacity:0.48
            }


            .label {
                flex-grow: 1;
                
            }

            .icon {
                width: 15px;
                font-size: 22px;
                font-weight: 600;
                line-height: 20px;
                text-align: center;
            }
        }

        .tree-child {
            //margin-left: 12px;

            .leaf-container {
                &:hover {
                    background: theme.$border-color !important;
                    color: theme.$black-color;
                }

                &.selected {
                    font-weight: 400;
                    color: theme.$white-color !important;
                    background-color: theme.$base-color;
                }
            }

            .selected {
                &.selected {
                    font-weight: 400;
                    color: theme.$white-color !important;
                    background-color: theme.$base-color;
                }
            }
        }

        .parent-node {
            .rotate180 {
                transform: rotate(180deg);
            }

            &:hover {
                background: theme.$border-color !important;
            }

            &.selected {
                font-weight: 700;
                color: theme.$base-color !important;
                //background-color: aqua;
            }
        }

        .leaf-node {
            margin-left: 12px;

            .label {
                overflow-wrap: anywhere;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .icon {
                display: none;
                flex-flow: column nowrap;
                justify-content: space-between;
                height: 22px;
                margin-top: -9px;

                span {
                    margin-top: 5px;
                    height: 2.5px;
                    line-height: 2.5px;
                }
            }
        }
    }

    .leaf-options {
        position: fixed;
        display: none;
        flex-direction: column;
        left: 0;
        top: 0;
        width: 110px;
        padding: 8px 0;
        background: theme.$white-color;
        border: 1px solid theme.$border-color !important;
        z-index: 30;

        span {
            padding: 6px 12px;
            cursor: pointer;

            &:hover {
                background: theme.$subtext-color;
            }
        }
    }

    &.main-tree-visible {
        >div {
            border-left: 3px solid #7777;
        }

        .main-tree-create {
            border-color: theme.$base-color;
        }
    }
}

.main-tree-handle {
    align-self: center;
    display: flex;
    align-items: center;
    border: 3px solid theme.$border-color;
    color: theme.$base-color;
    border-left: 0px;
    height: 70px;
    cursor: pointer;
}

.main-tree-create {
    border-top: 3px solid theme.$border-color;
    flex: 0 0 30px;

    .main-tree-create-button {
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        padding-top: 2px;
        color: theme.$base-color;
        cursor: pointer;
        height: 100%;
    }

    &:hover {
        border-color: theme.$base-color;
    }
}