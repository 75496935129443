$base-color: #26890d;
$base-color-disabled: #a8d09e;
$disabled-color: #777777;
$teal-color: #007680;
$blue-color: #0d2689;
$border-color: #d0d0ce;
$header-color: #53565a;
$error-color: #da291c;
$warning-color: #ffcd00;
$black-color: #000000;
$white-color: #ffffff;
$logs-border: #bbbcbc;
$subtext-color: #97999b;
$cool-gray: #63666A;