@use "../../theme";

.root-main {
  display: contents;
  .login-home-main {
    display: grid;
    height: 100%;
    width: 100%;

    .--body {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      height: 90%;
      margin-left: 5%;

      .--log-container {
        flex: 1;

        .--log {
          flex: 1;
          padding: 25px;
          width: 80%;
          height: 80%;
        }
      }

      .--content {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        height: 80%;
        //padding: 50px;
        flex: 1;
        text-align: left;

        .--bodyheader {
          display: flex;
          flex-direction: column;
          //height: 25%;
          width: 70%;
          margin-left: 10px;
        }

        .--bodycontainer {
          display: flex;
          flex-direction: column;
          //height: 25%;
          width: 70%;
          margin-bottom: 10px;
        }

        .--title-head {
          //height: 100px;
          margin-bottom: 30px;
        }

        .--forgot-password {
          color: theme.$base-color;
          display: flex;
          flex-direction: row-reverse;
          width: 90%;
        }

        .--formItem {
          margin: 10px;
          width: 90%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          label {
            display: block;
            margin-bottom: 8px;
            color: theme.$header-color;
          }

          input {
            border: 1px solid theme.$border-color;
            width: 100%;
            padding: 5px;
            outline: none !important;
          }
        }
      }
    }

    .--footercontainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 30%;
      width: 65%;

      .start-btn {
        width: 100%;
        padding: 10px 30px 10px 30px;
      }
    }
  }

  .--footer {
    align-items: center;
    border-top: 2px solid theme.$border-color;
    color: theme.$header-color;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    padding: 8px 20px;
  }
}

@media (-webkit-device-pixel-ratio: 1.5), (max-height: 768px) {
  .login-home-main {
    .--body {
      .--log {
        width: 65%;
        height: 65%;
      }
    }
  }
}

@media (-webkit-device-pixel-ratio: 1.25), (max-height: 768px) {
  .login-home-main {
    .--body {
      .--log {
        width: 85%;
        height: 85%;
      }
    }
  }
}
