@use "../../theme";

.SSO-config-container {
    width: 90%;
    height: 90%;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    justify-content: center;
    background: theme.$white-color;
    box-shadow: 1.09375px 2.1875px 7.65625px 4.375px rgba(132, 129, 129, 0.25);

    h3 {
        //margin-right: 41%;
        color: theme.$base-color;
        margin-bottom: 20px;
        margin-top: 30px;
        width: 60%
    }

    .other-config-div {
        width: 60%;
        height: 8%;
        margin: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;

        .dropdown-div {
            width: 90%;
        }

        .info {
            width: 30%;
            display: flex;
            flex-direction: row;
            //justify-content: center;
            align-items: center;
            position: absolute;
            left: 100%;


            img {
                margin-left: 5px;
                height: 20%;

            }
        }

        .admin-radio-button {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: center;
        }

        .radio-button {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 50%;

            label {
                margin-bottom: 0px;
            }
        }

        label {
            width: 50%;
            display: block;
            margin-bottom: 10px;
            //color: theme.$header-color;
        }
    }

    .other-config-btn {
        margin-top: 5%;
        width: 65%;
        display: flex;
        justify-content: end;
        position: absolute;
        bottom: 15%;
        left: 16%;

        //margin-top: 1%;

        .--savebtn {
            margin: 0px;
        }
    }
}