@use "../../theme";

.--edit-label-container {
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;

    .main-card {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        background: theme.$white-color;
        padding: 24px 24px 10px 24px;

        .--label-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0 0 16px 16px;
            border-bottom: 1px solid theme.$border-color;

            .heading {
                color: theme.$base-color;
            }
        }

        .--label-body {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            height: calc(100% - 61px);

            .--ltable-header,
            .--ltable-item {
                border-bottom: 1px solid theme.$border-color;
                padding: 15px 0;

                div {
                    align-self: center;
                }
            }

            .--ltable-sno,
            .--ltable-edit,
            .--ltable-del,
            .--ltable-col {
                text-align: center;
            }

            .--ltable-body {
                font-weight: 400;
                overflow-y: auto;

                .--ltable-item {

                    .--ltable-edit,
                    .--ltable-del {
                        &.disabled {
                            opacity: 0.4;
                        }
                    }

                    .--ltable-col {
                        display: flex;
                        justify-content: center;

                        .--color-dot {
                            height: 24px;
                            width: 24px;
                            border-radius: 32px;
                        }
                    }
                }
            }

            .--ltable-header {
                color: theme.$header-color;
            }

            &.--empty {
                align-items: center;
                margin-top: 70px;

                .--tag-icon {
                    margin-bottom: 25px;
                }

                .--label-item {
                    margin: 0;
                }
            }
        }

        .--label-footer {
            display: flex;
            flex-direction: row-reverse;
            color: theme.$subtext-color;

            .--savebtn {
                width: 115px;
            }
        }
    }

    .main-block-screen {
        .popup-main {
            // .body-content {
            //     margin-left: 12px;
            // }

            .--footer {
                .btn-main {
                    font-weight: 400;
                    height: 32px;
                    padding: 5px 10px;
                    width: 68px;
                }

                .--testsave {
                    background-color: theme.$error-color;
                }
            }
        }
    }
}