@use "../../theme";

.create-project-container {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: theme.$white-color;
    box-shadow: 1.09375px 2.1875px 7.65625px 4.375px rgba(132, 129, 129, 0.25);

    h3 {
        width: 60%;
        color: theme.$base-color;
        margin-bottom: 20px;
        margin-top: 30px;

    }

    .create-Project-div {
        display: flex;
        flex-direction: row;
        width: 60%;
        margin: 10px;
        justify-content: space-between;
        align-items: center;

        label {
            width: 30%;
            display: block;
            margin-bottom: 10px;
        }

        .apptype-select {
            width: 100%;
        }
    }

    .select-app-div {
        width: 60%;
        display: flex;
        margin-bottom: 3%;

        // .apptype-select {

        //     width: 70%;
        //     display: flex;
        //     flex-direction: column;
        //     border: 1px solid gray;
        // }
    }

    .select-app {
        height: 18%;
        display: flex;
        width: 60%;
        justify-content: space-between;
        margin-bottom: 2%;

        .apptype-select {
            width: 16%;
            display: flex;
            flex-direction: column;
            //justify-content: space-around;
            border: 2px solid theme.$border-color;
            align-items: center;

            div {
                width: 100%;
                margin-left: 10%;
            }

            img {
                width: 35%;
                height: 80%;
            }
        }

        .selectedApp {
            border: 2px solid theme.$base-color;
        }
    }

    .create-project-btn {
        display: flex;
        width: 60%;
        align-items: flex-end;
        justify-content: end;
        margin-top: 5%;

        .--savebtn {
            width: 20%;
        }

    }
}