@use "../../theme";

.create-apiKey-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 90%;
    background: theme.$white-color;
    box-shadow: 1px 2px 8px 4px rgba(132, 129, 129, 0.25);
    justify-content: center;
    align-items: center;

    h3 {
        width: 70%;
        color: theme.$base-color;
        margin-bottom: 20px;
    }

    .form-body {
        width: 70%;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .--fields {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .--input {
                width: 85%;
                outline: none;
                border: 1px solid theme.$border-color;
                padding: 6px 12px;
            }

            .--date-picker {
                width: 85%;
                height: 32px;
            }
            .--date-select{
                height:32px;
            }

            .--api-key {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: theme.$cool-gray;
                border: 1px solid theme.$border-color;
                padding: 6px 12px;
                width: 85%;
                height: 32px;

                p::selection {
                    color: theme.$white-color;
                    background: theme.$header-color;
                }

                span {
                    display: flex;
                    gap: 8px;


                    .--copy {
                        cursor: pointer;
                    }

                    .--copy.disabled {
                        pointer-events: none;
                        opacity: 0.3;
                        padding-right: 10px;
                    }

                    .--import {
                        cursor: pointer;
                    }

                    .--import.disabled {
                        pointer-events: none;
                        opacity: 0.3;                        
                    }
                }
            }
        }

    }

}