@use "../../theme";

.--create-label-container {
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;

    .main-card {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        background: theme.$white-color;
        padding: 50px 10%;

        .--label-header {
            display: flex;
            flex-direction: column;
            color: theme.$subtext-color;

            .heading {
                color: theme.$base-color;
            }
        }

        .--label-body {
            display: flex;
            flex-direction: column;

            .--label-item {
                margin: 25px 0;
                display: flex;
                flex-direction: row;
                align-items: center;

                label {
                    flex-basis: 150px;
                }

                .--label-item-namebox {
                    outline: none;
                    border-radius: 0;
                    border: 1px solid theme.$border-color;
                    height: 32px;
                    padding: 2px 4px;
                    width: 320px;

                    &.invalid {
                        border-color: theme.$error-color;
                    }
                }

                .--label-item-colorbox {
                    padding: 2px;
                    width: 320px;

                    .--label-item-color {
                        margin: 0 12px;
                        height: 32px;
                        width: 32px;
                        padding: 2px;
                        border: 2px solid theme.$border-color;
                        border-radius: 32px;

                        span {
                            height: 100%;
                            width: 100%;
                            display: block;
                            border-radius: 28px;
                        }

                        &.selected {
                            border-color: theme.$base-color;
                        }
                    }
                }
            }

            &.--max-limit {
                align-items: center;

                .--tag-icon {
                    margin-bottom: 25px;
                }

                .--label-item {
                    margin: 0;
                }
            }
        }

        .--label-footer {
            display: flex;
            flex-direction: row-reverse;
            color: theme.$subtext-color;

            .--savebtn {
                width: 117px;
            }
        }
    }
}